footer{
  & a:last-child{
    img{
      height: 67px;
    }
  }
  img{
    height: 80px;

  }
}