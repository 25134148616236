#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #050505;
  z-index: 2000;
  height: 100%;
  width: 100%;
}
.no-js #preloader, .oldie #preloader {
  display: none;
}
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  padding: 0;
}
#loader:before {
  content: "";
  border-top: 6px solid rgba(255, 255, 255, 0.1);
  border-right: 6px solid rgba(255, 255, 255, 0.1);
  border-bottom: 6px solid rgba(255, 255, 255, 0.1);
  border-left: 6px solid #FFFFFF;
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
  display: block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}