.contact-input.message{
  @media(max-width: 767px){
    padding: 0;
  }
}
#floor-plans-wrapper{
  position: relative;
  //left: 9%;
  @include media-breakpoint-down(xs) {
    left: 0;
  }
  img{
    max-width: 100%;
    margin-top: 8px;
    /* padding: 19px; */
    @include media-breakpoint-down(xs) {
      max-width: 90%;
    }
  }
}
#floorplans_detail {
  margin-top: 0;
  color: #333;
  .container{
    max-width: 100%;
  }
  .col-sm-12{
   padding: 0;
  }
  .col-sm-6{
   padding: 0;
    @include media-breakpoint-down(xs) {
      padding-right: 15px;
      padding-left: 15px;
      margin: 0;
    }
  }
}

#floorplans_detail .section_header {
  width: 45%;
  margin-bottom: 0;
  float: left;
  clear: none;
  padding-top: 0;
  @include media-breakpoint-down(xs) {
    width: 100%;
    float: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
}

#floorplans_detail .section_header h2 {
  text-transform: uppercase;
  font-weight: 400;
  margin: 20px 0 0 8px;
  clear: both;
  float: left;
  color: #646464;
  font-size: 36px;
  @include media-breakpoint-down(xs) {
    margin: 25px 0 0 0;
    float: none;
  }
}

#floorplans_detail .logo_plan {
  height: 36px;
  width: 310px;
  margin: 9px -9px 0;
  padding: 0;
  float: right;
  clear: none;
  @include media-breakpoint-down(xs) {
    margin: 0;
  }
}

#floorplans_detail .phone {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  width: 110px;
  height: 20px;
  float: right;
  clear: none;
  text-align: left;
  color: #646464;
  position: relative;
  left: 15px;
  @include media-breakpoint-down(xs) {
    left: 10px;
    visibility: hidden;
  }
}

#contact .nav-tabs.nav-justified > li > a, #home .section_header h3, #neighborhood .nav-tabs.nav-justified > li > a {
  text-align: center
}

#floorplans_detail .horz_line {
  margin: 6px 0;
  width: 100%;
  padding: 0;
  background: #E4E4E4;
  height: 1px;
}

#floorplans_detail .specs {
  font-size: 15px;
  margin: 0 0 10px;
  height: 17px
}

#floorplans_detail .print {
  font-size: 15px;
  margin: -1px 0 10px;
  height: 20px
}

#floorplans_detail .print img {
  margin-top: -3px
}

#floorplans_detail .left {
  float: left;
  clear: none
}

#floorplans_detail .print .left {
  padding-top: 8px;
  float: left;
  clear: none;
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(xs) {
    float: none;
    margin: 0;
  }
}

#floorplans_detail .right {
  float: right;
  clear: none;
  @include media-breakpoint-down(xs) {
    float: none;
    text-align: center;
  }
}

#floorplans_detail .right img {
  margin-bottom: 0;
  vertical-align: middle
}

#floorplans_detail .print a {
  color: #333
}

#floorplans_detail .right a {
  color: #fff;
  font-weight: 700;
  background-color:#A5B489 !important;
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 3px;
  display: inline-block;
  -webkit-transition: opacity .1s linear;
  -moz-transition: opacity .1s linear;
  transition: opacity .1s linear
}

#floorplans_detail .print .right a:hover {
  opacity: .8
}

#floorplans_detail .left_box {
  margin-left: 0
}

#floorplans_detail .left_box .big {
  margin-bottom: 40px;
  position: relative;
  height: 431px
}

#floorplans_detail .left_box .big img {
  position: absolute;
  border-radius: 5px
}

#floorplans_detail .thumbs .thumb {
  float: left;
  position: relative;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 10px
}

#floorplans_detail .thumbs .thumb.last {
  margin: 0
}

#floorplans_detail .thumbs .thumb:hover .mask {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100)
}

#floorplans_detail .thumbs .thumb .mask {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  box-shadow: 0 1px 6px 2px #b1b1b1;
  top: 0;
  left: 0;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  background: rgba(17, 137, 217, .5);
  -webkit-transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out
}

#apply input[type=submit]:hover, #contact .contact .submit .right input[type=submit]:hover {
  opacity: .8
}

#floorplans_detail .thumbs .thumb .mask .more {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  bottom: 40%;
  left: 43%
}

#floorplans_detail .right_box {
  float: right;
  margin-top: 14px
}

#floorplans_detail .right_box h2 {
  font-size: 28px;
  line-height: 27px;
  margin: 0 0 25px
}

#floorplans_detail .right_box p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 18px;
  color: #3F3F3F
}

@media (min-width: 768px) and (max-width: 991px) {
  #floorplans_detail .left_box .big {
    height: 372px
  }
}

@media (max-width: 480px) {
  #floorplans_detail .left_box {
    margin-bottom: 10px
  }

  #floorplans_detail .left_box .big {
    max-height: 440px;
    margin-top: 0
  }

  #floorplans_detail .left_box .thumbs {
    overflow: hidden
  }

  #floorplans_detail .print {
    font-size: 15px;
    margin: 0;
    height: 20px;
    margin-top: 49px;
    margin-bottom: 37px;
    text-align: center;
  }

  #floorplans_detail .specs {
    margin: 0 0 10px
  }
}

@media (max-width: 480px) {
  #floorplans_detail .left_box {
    margin-bottom: 10px
  }

  #floorplans_detail .left_box .big {
    max-height: 165px;
    margin-top: 15px
  }

  #floorplans_detail .left_box .big img {
    margin-bottom: 10px;
    max-height: 225px;
    width: 100%
  }

  #floorplans_detail .thumbs .thumb {
    margin-right: 5px
  }

  #floorplans_detail .specs {
    font-size: 15px;
    margin: 10px 0;
    height: 17px;
    text-align: center
  }

  #floorplans_detail .specs .left, #floorplans_detail .specs .right {
    float: none;
    clear: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
  #floorplans_detail .left_box .big {
    max-height: 325px;
    margin-top: 0
  }
}