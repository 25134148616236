.section_header {
  width: 100%;
  margin-bottom: 21px;
  padding-top: 14px;
  h3 {
    color: $primary-color;
    font-weight: lighter;
    font-size: 27px;
    text-transform: uppercase;
    padding-bottom: 0;
    margin: 0;
    width: 100%;
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }
  .callout1 {
    color: $primary-color;
    font-weight: 400;
  }
  .blue-dash {
    border-bottom: 1px dashed $primary-color;
    width: 312px;
    padding: 10px 0 0;
    margin-bottom: 22px;
  }
}
.btn-primary{
  background: #b3af59;
  padding: 8px 15px 3px;
  border-radius: 3px;
  color: white;
}
#welcome{
  a{
    color: $primary-color;
    font-weight: lighter;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
  }
}
.card{
  img{
    //height: 200px;
    max-width: 100%;
  }
 }
.gallery-image{
  padding: 0 4px 0 0;
  @include media-breakpoint-down(sm) {
    padding: 7px 14px;
  }
  img{
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 373px;
    }
  }
}

.card-title{
  color: $primary-color;
  font-weight: lighter;
  text-transform: uppercase;
}

section{
  padding: 45px 0 68px 0;
  @include media-breakpoint-down(sm) {
    padding: 37px 0;
  }
}
#jp-video{
  .blue-dash{
    width: 231px;
  }
  @include media-breakpoint-down(sm) {
    iframe{
      height: 300px;
    }
  }
}
#features{
  .blue-dash{
    width: 112px;
  }
  .card-content{
    padding-bottom: 25px;
  }
  .carousel-caption{
    background: rgba(12, 12, 12, 0.5);
    position: absolute;
    height: 64px;
    right: 0;
    bottom: 42px;
    left: 0;
    width: 100%;
  }
}
#floor-plans{
  /*background: url("../_img/Legacy_1-3.jpg") no-repeat;
  background-attachment: fixed;
  position: relative;
  padding: 45px 0 1px 0;*/
  .gallery-image{
    &:nth-child(2){
      p{
        padding-left: 13px;
      }
    }
    &:nth-child(3){
      p{
        padding-left: 13px;
      }
    }
    img{
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 305px;
      }
    }
  }
  .card{
    padding: 0;
    cursor: pointer;
    /* width: 215px; */
    /* height: 206px; */
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin: 5px;
    p{
      color: black;
      padding-left: 13px;
      font-size: 12px;
    }
  }
 /* h4{
    background: #A6B7FF;
    text-align: center;
    position: relative;
    color: #1B1E25;
    font-weight: 100;
    text-transform: uppercase;
    padding: 10px;
    margin-top: 38px;
    top: -43px;
    a{
      color: #1B1E25;
      text-decoration: none;
    }
  }*/

}
#contact-info{
  background: url("../_img/Legacy_1-3.jpg") 0px -645px no-repeat;
  background-attachment: fixed;
  position: relative;
  /*padding: 45px 0 1px 0;*/
  padding: 162px;
  h2{
    color: white;
    font-size: 31px;
    text-transform: uppercase;
    padding-bottom: 29px;
  }
  .btn{
    background: #b3af59;
    padding: 8px 15px 3px;
    border-radius: 3px;
    font-size: 20px;
    &:hover{
      background: #383838;
      opacity: .9;
    }
  }
  h3{
    color: white;
    position: relative;
    @include media-breakpoint-down(sm) {
     text-align: center;
    }
  }
  .blue-dash{
    border-bottom: 1px dashed #ffffff;
    width: 264px;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 227px;
      margin-left: 20%;
    }
  }
  .contact-list{
    color: white;
    li{
      padding-bottom: 15px;
    }
  }
  .right-column{
    margin-top: 3px;
  }
  .fa{
    font-size: 35px;
    float: left;
    margin: 4px 15px 0px 0px;
    height: 48px;
  }
  .fa-map-marker{
    margin: 4px 19px 0px 6px;
  }
  .fa-envelope-o{
    font-size: 29px;
    height: 51px;
    margin: 4px 18px 0px 0px;
  }
  .fa-user{
    height: 51px;
  }
  .card{
    cursor: pointer;
  }
  &:before{
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
  }
  #contact{
    margin-bottom: 84px;
  }
  #contact-form{
    background: $primary-color;
    padding: 23px;
    border-radius: 5px;
    margin-top: 81px;
    .section_header{
      padding-top: 0;
    }
    .contact-input:first-child{
      padding: 0;
    }
    @include media-breakpoint-down(sm) {
      margin: 15px;
    }
    .section_header{
      h3{
        color: white;
      }
      .blue-dash{
        border-bottom: 1px dashed #ffffff;
      }

    }
  }
}
.contact-info{
  .contact-input{
    padding: 0;
  }
  .btn{
    background: #b3af59;
    padding: 8px 15px 3px;
    border-radius: 3px;
    font-size: 17px;
    color: white;
    &:hover{
      color: white;
    }
  }
  p:last-child{
    padding-bottom: 23px;
  }
  .input-group > .form-control{
    font-size: 17px;
  }
}
#page-contact{
  .content{
    padding-bottom: 44px;
    h2{
      padding-bottom: 10px;
    }
  }
  .contact-list a, .contact-info a{
    color: #b3af59;
  }
  section{
    padding: 61px 0 21px 0;
  }
  #contact-us-form{
    .col-md-12{
      padding: 0;
    }
  }
  .contact-list li{
    padding-bottom: 11px;
  }
}

#page-amenities{
  .section-info{
    padding: 0px 0 68px 0;
  }
  .header-background{
    margin-bottom: 41px;
  }
  .header-bg{
    border: none;
    position: relative;
    width: 100%;
    height: 438px;
    &:after{
      content: " ";
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 1;
    }
  }
  li{
    padding: 4px 0px 9px 0px;
  }
  .bolder{
    font-weight: 600;
  }
}
label.error{
  display: block;
  width: 100%;
  color: #b2b153;
}
.content-message{
  i, p, h2{
    color: #b3af59;
  }
}
.contact_success {
  background: #F0F0F0;
  //border: 9px solid white;
}

.contact_success .content-message {
  text-align: center;
  padding: 155px 0 0 0;
  color: white;
}

.contact_success .content-message .fa {
  font-size: 78px;
  padding-bottom: 11px;
  color: #B7AA65;
}

.contact_success .content-message h2 {
  margin-bottom: 7px;
  color: #B7AA65;
}

.contact_success .content-message p {
  font-size: 19px;
  padding: 9px 36px 0px 36px;
  color: #333;
}

#footer-logos{
  img:first-child{
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 232px;
      height: 86px;
    }
  }
}