/* ##### Portfolio ##### */

#rj-portfolio{
  .blue-dash{
  width: 232px !important;
  }
}


.filter-portfolio ul {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.filter-portfolio ul > li {
  display: inline-block;
  margin: 0px 3px 10px 3px;
}

.filter-portfolio ul > li.active > a {
  font-weight: 700;
}

.popup-portfolio {
  margin: 0 auto;
}

.popup-portfolio .portfolio-item {
  width: 270px;

}

@media (min-width: 600px) {
  .popup-portfolio .portfolio-item {
    width: 320px;
  }
}

@media (min-width: 992px) {
  .popup-portfolio .portfolio-item {
    width: 273px;
  }
}

@media (min-width: 1024px) {
  .popup-portfolio.two-columns .portfolio-item {
    width: 562px;
  }
}

@media (min-width: 1024px) {
  .popup-portfolio.three-columns .portfolio-item {
    width: 370px;
  }
}

.portfolio-item {
  margin-bottom: 15px;
}

.portfolio-item > .inner-content {
  position: relative;
  display: block;
  overflow: hidden;
  opacity: 1 !important;
  &:hover img{
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

.portfolio-item > .inner-content:hover {
  cursor: pointer;
}

.portfolio-item > .inner-content:hover > .portfolio-content {
  opacity: .8;

}

.portfolio-item > .inner-content > .portfolio-content {
 opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #000;
  filter: alpha(opacity=70);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=70)";
  transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
}

.portfolio-item > .inner-content > .portfolio-content > .portfolio-detail {
  width: 100%;
  height: 100%;
}

.portfolio-item > .inner-content > .portfolio-content > .portfolio-detail a {
  text-align: center;
  display: table;
  width: 100%;
  height: 100%;
}

.portfolio-item > .inner-content > .portfolio-content > .portfolio-detail a > .portfolio-text {
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
  color: #fff;
  letter-spacing: 0.2em;
}

.portfolio-item > .inner-content > .portfolio-content > .portfolio-detail a > .portfolio-text h4 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

.portfolio-item > .inner-content > .portfolio-content > .portfolio-detail a > .portfolio-text p {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}

.portfolio-item > .inner-content > img {
  /*-webkit-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);*/
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  width: 100%;
}


/* ##### Portfolio Grow Effect ##### */

.portfolio-item.grow > .inner-content:hover > img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}


/* ##### Portfolio Grow Rotate Effect ##### */

.portfolio-item.grow-rotate > .inner-content:hover > img {
  -webkit-transform: scale(1.2) rotate(4deg);
  transform: scale(1.2) rotate(4deg);
}


/* ##### Portfolio Shrink Effect ##### */

.portfolio-item.shrink > .inner-content:hover > img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.portfolio-item.shrink > .inner-content > img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}


/* ##### Portfolio Shrink Rotate Effect ##### */

.portfolio-item.shrink-rotate > .inner-content:hover > img {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

.portfolio-item.shrink-rotate > .inner-content > img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}


/* ##### Portfolio Slide In Left Effect ##### */

.portfolio-item.slide-in-left > .inner-content:hover > .portfolio-content {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.portfolio-item.slide-in-left > .inner-content > .portfolio-content {
  display: block;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}


/* ##### Portfolio Pop Effect ##### */

.portfolio-item.pop > .inner-content:hover > img {
  -webkit-animation-name: pop;
  animation-name: pop;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.portfolio-item.pop > .inner-content > img {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes pop {
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pop {
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


/* ##### Portfolio Pop Rotate Effect ##### */

.portfolio-item.pop-rotate > .inner-content:hover > img {
  -webkit-animation-name: pop-rotate;
  animation-name: pop-rotate;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;

}
.portfolio-item.pop-rotate > .inner-content > img {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes pop-rotate {
  50% {
    -webkit-transform: scale(1.3) rotate(4deg);
    transform: scale(1.3) rotate(4deg);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pop-rotate {
  50% {
    -webkit-transform: scale(1.3) rotate(4deg);
    transform: scale(1.3) rotate(4deg);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


/* ##### Portfolio Push Effect ##### */

.portfolio-item.push > .inner-content:hover > img {
  -webkit-animation-name: push;
  animation-name: push;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.portfolio-item.push > .inner-content > img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

@-webkit-keyframes push {
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

@keyframes push {
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}


/* ##### Portfolio Push Rotate Effect ##### */

.portfolio-item.push-rotate > .inner-content:hover > img {
  -webkit-animation-name: push-rotate;
  animation-name: push-rotate;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.portfolio-item.push-rotate > .inner-content > img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

@-webkit-keyframes push-rotate {
  50% {
    -webkit-transform: scale(1.1) rotate(4deg);
    transform: scale(1.1) rotate(4deg);
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

@keyframes push-rotate {
  50% {
    -webkit-transform: scale(1.1) rotate(4deg);
    transform: scale(1.1) rotate(4deg);
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}


#neighborhood-grid{
  .grid-item{
    width: 32.8%;
    @media (max-width: 736px) {
      width: 100%;
    }
  }
  @include media-breakpoint-down(xs) {
    .grid-item{
      //padding: 0 0 17px 14px;
      margin: 7px 14px;
      img{
        width: 100%;
      }
    }
  }
}