/*!
 * Start Bootstrap - Modern Business (http://startbootstrap.com/template-overviews/modern-business)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-logomodern-business-nav/blob/master/LICENSE)
 */

body {
    //padding-top: 54px;
  font-family: $font-family;
    font-weight: 100;
  position: relative;
}

@media (min-width: 992px) {
    body {
        //padding-top: 56px;
    }
}
a{
  color: white;
  text-decoration: none !important;
  &:hover{
    color: #A7B28A;
  }
}
p{
    @include media-breakpoint-down(sm) {
        padding: 0px 32px;
    }
}
strong{
  font-weight: 900;
}
.row{
  margin: 0;
}
.carousel-item {
  height: 38vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @include media-breakpoint-down(sm) {
    height: 38vh;
  }
}
.portfolio-item {
    margin-bottom: 30px;
}


.bg-light{
    background-color: $secondary-color !important;
}
.bg-grey{
    background-color: #f5f5f5 !important;
}
.bg-dark-grey{
  background-color: #3F3F3F !important;
}
.card-body{
  padding: 0;
}

.btn{
    background: #383838;
    border: none;

  &:hover{
    background: #383838;
    opacity: .9;
  }
}

.fancybox-nav{
  height: 90%;
}