.navbar-nav{
  position: relative;
  //left: 9.6%;
 /* left: 3.3%;*/
  //margin-top: 4px;
  .nav-pos{
    position: relative;
    top: 6px;
  }
}
.navbar-expand-lg .navbar-collapse{
  position: relative;
  //left: 2.9%;
}
.navbar-expand-lg .navbar-nav .nav-link{
  padding-right: .5rem;
  padding-left: .5rem;
}
.sticky-top{
  top: -4px;
}
.navbar-brand{
  @include media-breakpoint-down(md) {
    display: block !important;
    img{
      width: 165px;
    }
  }
}
.navbar-light .navbar-nav .nav-link{
  color: #fff;
  &.active, &.bold{
    color: #363636;
    font-weight: 500;
  }
}

#logo{
  width: 298px;
  position: relative;
  top: -2px;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
  img{
    width: 200px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 284px;
    }
  }

}

#collapsingNavbar2{
  @include media-breakpoint-down(sm) {
    border-top: 1px solid #565656;
    margin-top: 8px;
    text-align: center;
    .navbar-nav{
     left: 0;
    }
  }
}

#social-icons{
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
