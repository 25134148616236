#header-slider{
  .overlay{
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(6, 6, 6, 0.27);
    z-index: 9;
  }
  .carousel-item {
    height: 95vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @include media-breakpoint-down(sm) {
      height: 91vh;
    }
  }
  .carousel-caption{
    top: 2%;
    left: 1%;
    right: 82%;
    img{
      width: 383px;
    }
    @include media-breakpoint-down(sm) {
      bottom: 43%;
      left: 15%;
      right: 15%;
      img{
        width: 221px;
      }
    }
  }
  .carousel-control-prev, .carousel-control-next{
    display: none;
  }
}







