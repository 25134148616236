/* padding-bottom and top for image */
.mfp-no-margins img.mfp-img {
  padding: 0;
}
/* position of shadow behind the image */
.mfp-no-margins .mfp-figure:after {
  top: 0;
  bottom: 0;
}
/* padding for main container */
.mfp-no-margins .mfp-container {
  padding: 0;
}


/*

for zoom animation
uncomment this part if you haven't added this code anywhere else

*/
/*

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
		opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
		opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}
*/

#gallery, #page-home{
  .container{
    padding: 0;
  }
  .section_header{
    margin-bottom: 25px;
    padding-top: 45px;
    .blue-dash{
      width: 126px;
    }
  }
  .popup-gallery{
    //padding-top: 20px;
    padding-bottom: 100px;
    margin-left: 10px;
    .item{
      //padding: 0 0 17px 17px;
      img{
        width: 100%;
        -webkit-transition: all 0.5s linear;
        transition: all 0.5s linear;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
      }
    }
    @include media-breakpoint-down(xs) {
      .grid-item{
        //padding: 0 0 17px 14px;
        padding: 0px 14px;
        img{
          width: 100%;
        }
      }
    }
  }
}

.grid {
  max-width: 1200px;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- grid-item ---- */

.grid-sizer,
.grid-item {
  width: 24.5%;
  margin-right: 5px;
  margin-bottom: 5px;
  @include media-breakpoint-down(lg) {
    width: 23%;
  }
  @include media-breakpoint-down(md) {
    width: 23%;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 15px;
    //left: 10% !important;
    //left: -3% !important;
  }
  @media (max-width: 321px){
    width: 100%;
    margin-bottom: 15px;
    //left: 4% !important;
  }
}

.grid-item {
  height: auto;
  float: left;
  position: relative;
  &:hover img{
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
  &:hover{
    opacity: .8;
  }
  i{
    position: absolute;
    z-index: 11;
    opacity: 1;
    font-size: 36px;
    top: 77px;
    color: white;
    left: 43%;
  }
  &:hover .content{
    opacity: 1;
    transition: opacity .4s ease;
  }
  .content{
    position: absolute;
    top: 0px;
    left: 15px;
    right: 0;
    bottom: 0px;
    z-index: 3;
    opacity: 0;
    transition: opacity .4s ease;
    background: rgba(34, 34, 34, 0.85);
    width: 100%;
    cursor: pointer;
    i{
      color: white;
      font-size: 50px;
      position: absolute;
      top: 38%;
      font-size: 44px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      width: 40px;
      margin: 0 auto;
      -webkit-transform: translate(0px, 0px);
      -ms-transform: translate(0px, 0px);
      transform: translate(0px, 0px);
      transition: all .3s ease;
    }
  }
}

.grid-item--width2 { width:  40%; }
.grid-item--width3 { width:  60%; }

.grid-item--height2 { height: 200px; }
.grid-item--height3 { height: 260px; }
.grid-item--height4 { height: 360px; }
