/*!
 * Innovate Digital - Dancor
 * Copyright 2016 Dancor Solutions, Inc.,
 * Updated: 2019.03.11_132017 by dstarr
 */

//VENDOR
/*@import "../../vendor/sass/animate/animate.min";*/
// BOOTSTRAP SASS -- Imported from Node
@import "../../node_modules/bootstrap/scss/bootstrap";

// ANIMATE CSS -- Imported from Node
//@import "../../node_modules/animate.css/animate.min.css";

// FONT AWESOME -- Imported from Bower
@import "../../vendor/sass/font-awesome/font-awesome_updated";

// ### fancybox
@import "../../vendor/sass/fancybox/jquery.fancybox-updated";

@import url("https://use.typekit.net/nnr0vnh.css");


// MAIN
//@import "helpers/helpers";
@import "helpers/variables";
@import "base/base";

//@import "base/fonts";
@import "components/components";
@import "pages/pages";
//@import "themes/themes";

// Plugins
//@import "vendor/vendor";
//@import "vendor/jquery_ui/datepicker";
@import "../../vendor/sass/magnific-popup/main";